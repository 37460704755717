import request from '@/utils/request/index'
import Cookies from 'js-cookie'
export default{
    namespaced:true,
    state:{
        userInfo:{}
    },
    mutations:{
        SET_USER_INFO(state,obj){
            state.userInfo = obj
        }
    },
    actions:{
        async getUserInfo(context, params){
            let res = await request({
                method:'get',
                headers:{
                    isToken:false
                },
                url:'/app/signUsers/getUserInfo',
                params,
            })
            if(res.code==200){
                context.commit('SET_USER_INFO',res.result)
            }
            return res
        },
        // 登录
        async login(context, params) {
            let res = await request.post(`/client/login/toLogin`,params)
            if(res.code==200){
                Cookies.set('admin_yq_token',res.result.token)
                Cookies.set('user_id',res.result.signUsers.id)
                localStorage.setItem('admin_yq_token',res.result.token)
                localStorage.setItem('user_id',res.result.signUsers.id)
                context.commit('SET_USER_INFO',{...res.result.signUsers,token:res.result.token})
            }
            return res
        },
        // 获取
        async getSmsCode(context, params){//
            return await request.post(`/client/login/getSmsCode`,params)
        },
        async getSmsCodePc(context, params){//
            return await request.post(`/client/login/getSmsCodePc`,params)
        },
        // 用户注册
        async register(context, params){//
            return await request.post(`/client/login/register`,params)
        },
        // 获取隐私政策
        async getPrivacyAgreement(context, params){//
            return await request.get(`/client/signUser/getPrivacyAgreement`)
        },
        // 用户协议
        async getRegistrationProtocol(context, params){//
            return await request.get(`/client/signUser/getRegistrationProtocol`)
        },
        // 工作台
        async clientHomeIndex(context, params){//
            return await request.get(`/client/clientHome/index`)
        },


        // 开发平台测试接口 测试接口  start
        async enterpriseUserList(context,data) {
            return request.post(`/client/enterprise/enterpriseUserList`, data)
        },


        // 新增联系人
        async signContactsAdd(context,data) {
            return request.post(`/client/signUser/signContactsAdd`, data)
        },
        // 修改联系人
        async signContactsEdit(context,data) {
            return request.put(`/client/signUser/signContactsEdit`, data)
        },
        // 删除联系人
        async deleteBatch(context,data) {
            return request.delete(`/client/signUser/deleteBatch?ids=${data}`)
        },
        // end
    }
}
