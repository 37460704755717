import axios from "axios";
import { Message } from 'element-ui'
import Cookies from "js-cookie";
const ajax = axios.create({
  baseURL: "https://openapi.signzg.com/",
  // baseURL: "http://192.168.124.20:8015/",
  timeout: 40000//请求超时时间
})

// 是否显示重新登录
export let isRelogin = { show: false };


ajax.interceptors.request.use((config)=>{
  //   let res = await request({
  //     method:'get',
  //     headers:{
  //         isToken:false
  //     },
  //     url:'/app/signUsers/getUserInfo',
  //     params,
  //  })

  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  // 是否需要提交空字段
  const isSubmitEmpty= (config.headers || {}).submitEmpty  || false

  const token = Cookies.get('admin_yq_token')
  if (token && !isToken) {
    config.headers['X-Access-Token'] = token
  }

  if(!isSubmitEmpty){
    if( config.method=='get'){
      let obj = {}
      Object.keys(config.params||{}).forEach(k=>{
        let value  = config.params[k]
        if(value !== null && value !== "" && typeof (value) !== "undefined"){
          obj[k] = value
        }
      })
      config.params = obj
    }
    if(config.method=='post'|| config.method=='put'){
      let obj = {}
      Object.keys(config.data||{}).forEach(k=>{
        let value  = config.data[k]
        if(value !== null && value !== "" && typeof (value) !== "undefined"){
          obj[k] = value
        }
      })
      config.data = obj
    }

  }

  return config
},(error)=>{
  return Promise.reject(error)
})

ajax.interceptors.response.use((response)=>{
  let { data } = response
  if (data.code== 600) {
    store.dispatch('user/resetToken').then(() => {
      location.reload()
    })
  }else if (data.code ===200) {
    return data
  }else if(data.code == 201){
    Message({
      message: data.message,
      type: 'error',
      duration: 2000,
      onClose:()=>{
        // router.push({name:"login"})
      }
    })
    return data
  }else {
    return data
  }
},(err)=>{
  console.log("err",err)
  if(err.code=='ERR_NETWORK'){
    Message.warning('网路错误')
  }else if(err.code!='ECONNABORTED' || err.code=='ERR_BAD_RESPONSE'){ // 不是超时
    Cookies.remove('admin_yq_token')
    Cookies.remove('admin_id')
    Message.warning('Token失效，请重新登录')
    location.reload()
  }else{
    return resolve(err)
  }
})

export default ajax
