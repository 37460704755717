import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cookies from 'js-cookie'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "../../assets/css/main.css";
import "../../assets/css/base.css";
import { initUserInfo } from '@/utils/index'
import mixin from './mixin'
const bus = new Vue();
Vue.prototype.$BUS = bus;
Vue.mixin(mixin);
Vue.config.productionTip = false
Vue.config.errorHandler = function(err) {
  console.log("程序未处理的异常", err);
};
import * as filters from '../../utils/methods/filters'
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})
Vue.use(ElementUI)

import SliderVerify from './components/SliderVerify/index.vue'
Vue.component('SliderVerify', SliderVerify)

router.beforeEach(async (to, from, next) => {
  let userId = Cookies.get(`user_id`)
  let token = Cookies.get(`admin_yq_token`)
  if((!userId || !token) && to.name!='login'){
      next('/login')
  }else if(userId && token && !store.state.work.company_info && to.name!='notCertified'){ //enterpriseState
    next('/notCertified')
  }else if(userId && token && store.state.work.company_info && store.state.work.company_info.enterpriseState!=5 && to.name!='notCertified'){
    next('/notCertified')
  }else if(userId && token && store.state.work.company_info && store.state.work.company_info.enterpriseIdentifier && to.fullPath=='/'){
    next('/main/index')
  }  else{
    next()
  }
})

let VueInit = async ()=>{
  await initUserInfo(store)
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
VueInit()
